<template>
  <div class="Wiki">
    <div class="add">
      <div class="title">
        <span>常见病</span>
      </div>
      <div @click="openAddWiki" class="icon">
        <span class="el-icon-plus"></span>
      </div>
      <span class="font">新增</span>
    </div>
    <div class="search">
      <div class="item">
        <span>标题</span>
        <el-input></el-input>
      </div>
      <div class="item">
        <span>关键内容</span>
        <el-input></el-input>
      </div>
      <div class="item">
        <span>创建人</span>
        <el-input></el-input>
      </div>
      <div class="item">
        <span>创建时间</span>
        <el-date-picker class="margin" type="date" placeholder="选择日期">
        </el-date-picker>
        <el-date-picker type="date" placeholder="选择日期">
        </el-date-picker>
      </div>
      <div class="postion">
        <el-input class="input" placeholder="姓名/身份证号" suffix-icon="el-icon-search">
        </el-input>
      </div>
    </div>
    <el-table border :data="tableData" height="456" :header-cell-style="{ background: '#092B50' }">
      <el-table-column type="selection" width="55"></el-table-column>
      <el-table-column prop="name" label="所属分类"></el-table-column>
      <el-table-column label="标题"></el-table-column>
      <el-table-column label="阅读次数"></el-table-column>
      <el-table-column label="附件"></el-table-column>
      <el-table-column label="创建人"></el-table-column>
      <el-table-column label="创建时间"></el-table-column>
      <el-table-column label="操作" width="140">
        <!-- slot-scope="scope" -->
        <template>
          <div class="flex">
            <span>编辑</span>
            <span>修改</span>
            <span class="red">删除</span>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <AddWiki v-if="showAddWiki" @close="closeAddWiki"></AddWiki>
  </div>
</template>

<script>
import AddWiki from './addWiki.vue'
export default {
  components: {
    AddWiki
  },
  data () {
    return {
      tableData: [{
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }, {
        date: '2020-02-27 17:20',
        name: '20200314001001',
        type: '1',
        value: false
      }],
      showAddWiki: false
    }
  },
  methods: {
    openAddWiki () {
      this.showAddWiki = true
    },
    closeAddWiki () {
      this.showAddWiki = false
    }
  }
}
</script>

<style lang="less" scoped>
.Wiki {
  position: relative;
  width: 100%;
  height: 100%;

  .add {
    display: flex;
    align-items: center;
    width: 100%;
    height: 40px;
    padding-left: 3px;
    box-sizing: border-box;

    .title {
      width: 63px;
      height: 30px;
      line-height: 30px;
      text-align: center;
      color: #fff;
      font-size: 18px;
      background-color: #01EEFD;
    }

    .icon {
      width: 24px;
      height: 24px;
      line-height: 24px;
      margin-left: 15px;
      margin-right: 5px;
      text-align: center;
      color: #fff;
      font-size: 12px;
      font-weight: bolder;
      background-color: #01EEFD;
      border-radius: 50%;
      cursor: pointer;
    }

    .font {
      color: #01EEFD;
      font-size: 18px;
    }
  }

  .search {
    display: flex;
    align-items: center;
    width: 100%;
    height: 77px;
    padding: 0 8px;
    border: 1px solid #01EEFD;
    border-bottom: none;
    box-sizing: border-box;

    .item {
      display: flex;
      align-items: center;
      color: #01EEFD;
      margin-right: 24px;
      font-size: 18px;

      span {
        margin-right: 10px;
      }

      .el-input {
        width: 151px;
        min-width: 151px;
        height: 38px;
        line-height: 38px;
      }

      .el-date-picker {
        width: 151px;
        height: 38px;
        line-height: 38px;
      }

      .margin {
        margin-right: 10px;
      }
    }

    .postion {
      .input {
        background: linear-gradient(153deg, #0A477B 0%, #0E67AD 100%);
        border: 1px solid #1F72D3;
        border-radius: 6px;
      }

      /deep/.el-input__inner {
        background-color: transparent;
        color: #fff !important;
        font-size: 18px;
        border: none;
      }

      /deep/ .el-input__suffix {
        color: #fff;
        font-size: 18px;
      }
    }
  }

  .el-table {
    border: 1px #01EEFD solid;

  }

  ::v-deep .el-table__body-wrapper {
    &::-webkit-scrollbar {
      // 整个滚动条
      width: 0rem; // 纵向滚动条的宽度
    }
  }

  ::v-deep .el-table th.gutter {
    display: none;
    width: 0;
  }

  ::v-deep .el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  ::v-deep .el-table__body {
    width: 100% !important;
  }

  /deep/.el-table__header-wrapper {
    thead {
      th {
        div {
          color: #01EEFD;
          font-size: 17px;
          font-weight: 400;
        }
      }
    }
  }

  /deep/.el-table__body tbody tr {
    color: #fff;
    font-size: 16px;
    background-color: #092B50;
  }

  /deep/.el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
    background-color: #092B50;
  }

  /deep/.el-table tbody tr:hover>td {
    background-color: transparent !important
  }

  .el-table--border,
  .el-table--group {
    border: 2px solid #ddd !important;
  }

  .el-table--border td,
  .el-table--border th,
  .el-table__body-wrapper .el-table--border.is-scrolling-left~.el-table__fixed {
    border-right: .0125rem solid #ddd !important;
  }

  /deep/.el-table td {
    border-bottom: .0125rem solid #01EEFD;
  }

  /deep/.el-table thead tr th {
    border-color: #01EEFD;
  }

  /deep/ .el-table--border::after,
  /deep/ .el-table--group::after,
  /deep/ .el-table::before {
    background-color: #073F73;
  }

  /deep/.el-table--border {
    border: .0125rem solid #01EEFD !important;
  }

  /deep/.el-table .cell {
    text-align: center;
    color: #01EEFD;
  }

  /deep/.el-table th.gutter {
    display: none;
    width: 0rem;
  }

  /deep/.el-table colgroup col[name='gutter'] {
    display: none;
    width: 0;
  }

  /deep/.el-table--border .el-table__cell {
    border-color: #01EEFD;
  }

  /deep/.el-table--border .el-table__cell:nth-child(3) {
    border-right: none;
  }

  /deep/ .el-checkbox__inner {
    border-color: #01EEFD;
    background-color: transparent;
  }

  /deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
    background-color: #01EEFD;
  }

  /deep/ .el-table__body-wrapper {
    background-color: #092B50;
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    span {
      color: #01EEFD;
      cursor: pointer;
    }

    .red {
      color: #FF0000;
    }
  }
}
</style>
