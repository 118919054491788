<template>
  <div class="page">
    <div class="title">
      <span>发布</span>
    </div>
    <div class="content">
      <div @click="close" class="close">
        <span class="el-icon-close"></span>
      </div>
      <div class="box">
        <avue-ueditor v-model="text" v-bind="options"></avue-ueditor>
      </div>
      <div class="btns">
        <el-button @click="close">发布</el-button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      text: '',
      options: {
        // 普通上传地址
        action: '',
        customConfig: {}, // wangEditor编辑的配置
        // 是否启用oss(qiniu/ali)
        oss: '',
        headers: {},
        data: {},
        propsHttp: {
          home: '',
          url: 'url',
          res: 'data'
        },
        // 七牛云oss配置
        qiniu: {
          AK: '',
          SK: '',
          scope: '',
          url: '',
          deadline: 1
        },
        // 阿里云oss配置
        ali: {
          region: '',
          endpoint: '',
          accessKeyId: '',
          accessKeySecret: '',
          bucket: ''
        }
      }
    }
  },
  mounted () { },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 88;
  width: 100%;
  height: 100%;
  background-color: #08223D;

  .title {
    width: 3.3375rem;
    height: .6625rem;
    padding-top: .0625rem;
    box-sizing: border-box;
    line-height: .6625rem;
    text-align: center;
    background-image: url('../../img/ssjk/title.png');
    background-size: 100% 100%;
    color: #fff;
    font-size: .25rem;
    font-weight: 600;
  }

  .content {
    position: relative;
    width: 100%;
    min-width: 800px;
    height: calc(100% - 63px);
    margin-top: 10px;
    padding: 50px 65px;
    padding-top: 70px;
    background-image: url('../../img/dialogContentBg.png');
    background-size: 100% 100%;
    box-sizing: border-box;

    .box {
      width: 100%;
      height: calc(100% - 140px);
      margin-top: 40px;
    }

    .close {
      position: absolute;
      top: 30px;
      right: 32px;
      color: #fff;
      font-size: 34px;
      cursor: pointer;
    }

    .btns {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 40px;

      .el-button {
        text-align: center;
        background: linear-gradient(107deg, #0176CC 0%, #025697 60%, #025697 60%, #024073 100%);
        color: #fff;
        font-size: 14px;
        border: 0;
        border-radius: 0;
      }
    }
  }
}
</style>
