<template>
  <div class="healthedu">
    <div class="tab">
      <span v-for="(item, index) in tabs" :key="index" :class="{ title: tabIndex === index }" @click="tabClick(index)">{{ item }}</span>
    </div>
    <div class="content">
      <Consult v-if="tabIndex === 0"></Consult>
      <Wiki v-else-if="tabIndex === 1"></Wiki>
    </div>
  </div>
</template>

<script>
import Consult from './consult/consult.vue'
import Wiki from './wiki/wiki.vue'
export default {
  components: {
    Consult,
    Wiki
  },
  data () {
    return {
      tabIndex: 0,
      tabs: Object.freeze(['健康咨询', '疾病百科'])
    }
  },
  methods: {
    tabClick (index) {
      this.tabIndex = index
    }
  }
}
</script>

<style lang="less" scoped>
.healthedu {
  width: calc(100% - .125rem);
  height: calc(100% - .125rem);
  padding: .25rem;
  border: .05rem solid rgba(1, 124, 219, .27);
  box-sizing: border-box;
  background-color: #08223D;

  .tab {
    display: flex;
    align-items: center;
    width: 100%;
    height: .75rem;
    border-bottom: .0125rem solid #01EEFD;
    box-sizing: border-box;

    span {
      height: .475rem;
      line-height: .475rem;
      margin-right: .2rem;
      padding: 0 .15rem;
      text-align: center;
      color: #fff;
      font-size: .275rem;
      background-color: #D5D5D5;
      cursor: pointer;
    }

    .title {
      background-color: #01EEFD;
    }
  }

  .content {
    width: 100%;
    height: calc(100% - .875rem);
    margin-top: .125rem;
  }
}
</style>
